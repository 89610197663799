import React, { useEffect, useState } from "react";
import CardLayoutSection from "../../components/CardLayoutSection";
import ClientLayout from "../../components/client-Layout";
import HubHero from "../../components/HubHero";
import SplashScreen from "../../components/SplashScreen";
import TranslateComponent from "../../components/TranslateComponent";
import TranslationBanner from "../../components/TranslationBanner";
import WelcomeSection from "../../components/WelcomeSection";
import withAuth from "../../components/withAuth";

const ClientDashboard = () => {
	const [clientData, setClientData] = useState(null);
	const [languageCode, setLanguageCode] = useState("en");
	const [heroData, setHeroData] = useState({
		heading: "",
		body: "",
		image: "",
	});

	const handleLanguageChange = (code) => {
		setLanguageCode(code);
	};

	const fetchData = async (endpoint) => {
		const token = localStorage.getItem("token");

		try {
			const response = await fetch("/.netlify/functions/fetchData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ endpoint, token }),
			});

			const data = await response.json();

			if (!response.ok) {
				console.error(`Error fetching ${endpoint} data:`, data.message);
				return null;
			}

			return data;
		} catch (error) {
			console.error("Fetch error:", error);
			return null;
		}
	};

	useEffect(() => {
		const getData = async () => {
			const data = await fetchData("client?_fields=acf&acf_format=standard");

			if (data) {
				setClientData(data);
				console.log(data);
				// Set the hero data after fetching
				const heroSection = data[0]?.acf?.hero_section;
				setHeroData({
					heading: heroSection?.heading || "",
					body: heroSection?.body || "",
					image: heroSection?.image || "",
				});
			} else {
				setClientData(null);
			}
		};

		getData();
	}, []);

	const { heading, body, image } = heroData;

	return (
		<div>
			{clientData ? (
				<ClientLayout
					navLinks={clientData[0]?.acf.navbar_links}
					languageCode={languageCode}
				>
					<div>
						<div className="d-md-none">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>
						<HubHero
							customerName={clientData[0]?.acf?.company_name}
							afterText={
								<TranslateComponent code={languageCode} text={"hub"} />
							}
							heading={
								<TranslateComponent code={languageCode} text={heading} />
							}
							body={
								body.length > 1 ? (
									<TranslateComponent code={languageCode} text={body} />
								) : (
									""
								)
							}
							image={image}
						/>

						<div className="d-none d-md-block">
							<TranslationBanner
								languageCode={languageCode}
								onSelectLanguage={handleLanguageChange}
							/>
						</div>
						{/* Card Layout Section */}
						{clientData[0]?.acf?.card_layout && (
							<CardLayoutSection
								logo={clientData[0].acf.company_logo}
								languageCode={languageCode}
								heading={clientData[0].acf.card_layout.heading}
								body={clientData[0].acf.card_layout.body}
								boldText={clientData[0].acf.card_layout.bold_text}
								cards={clientData[0].acf.card_layout.cards.map((card) => ({
									...card,
									// The heading title and body will be translated inside CardLayoutSection
								}))}
							/>
						)}

						<WelcomeSection
							languageCode={languageCode}
							tagline={clientData[0]?.acf?.welcome_banner?.tagline}
							name={clientData[0]?.acf?.welcome_banner?.name}
							body={clientData[0]?.acf?.welcome_banner?.body}
							background_image={
								clientData[0]?.acf?.welcome_banner?.background_image
							}
						/>
					</div>
				</ClientLayout>
			) : (
				<SplashScreen />
			)}
		</div>
	);
};

export default withAuth(ClientDashboard, "client");
